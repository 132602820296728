import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem;
`;

const RankingSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 2rem;
`;

const Ranking = styled.div`
  border-radius: 5px;
  color: white;
`;

const RankingHead = styled.div`
  padding: 1rem;
  background: #e6c886;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 1.2rem;
  text-align: center;
  letter-spacing: 0.5rem;
`;

const RankingBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #999999;
  border-left: 1px solid #999999;
  border-right: 1px solid #999999;
`;

const Rank = styled.div``;

const Name = styled.div``;

const Result = styled.div``;

const RankingPage = ({ data }) => {
  let data1 = data.allRankingMatchCountJson.nodes;
  let data2 = data.allRankingWinCountJson.nodes;
  let data3 = data.allRankingWinAvgJson.nodes;
  let data4 = data.allRankingWinContinueJson.nodes;

  return (
    <Layout title="Ranking">
      <SEO pagetitle="Ranking" pagedesc="棋士のランキングについて" />
      <ContentWrapper>
        <Main>
          <RankingSection>
            <Ranking>
              <RankingHead>対局</RankingHead>
              {data1.map((tmp) => (
                <RankingBody>
                  <Rank>{tmp.rank}.</Rank>
                  <Name>{tmp.name}</Name>
                  <Result>{tmp.result}</Result>
                </RankingBody>
              ))}
            </Ranking>
            <Ranking>
              <RankingHead>勝数</RankingHead>
              {data2.map((tmp) => (
                <RankingBody>
                  <Rank>{tmp.rank}.</Rank>
                  <Name>{tmp.name}</Name>
                  <Result>{tmp.result}</Result>
                </RankingBody>
              ))}
            </Ranking>
            <Ranking>
              <RankingHead>勝率</RankingHead>
              {data3.map((tmp) => (
                <RankingBody>
                  <Rank>{tmp.rank}.</Rank>
                  <Name>{tmp.name}</Name>
                  <Result>{tmp.result.split("（")[0].trim()}</Result>
                </RankingBody>
              ))}
            </Ranking>
            <Ranking>
              <RankingHead>連勝</RankingHead>
              {data4.map((tmp) => (
                <RankingBody>
                  <Rank>{tmp.rank}.</Rank>
                  <Name>{tmp.name}</Name>
                  <Result>{tmp.result}</Result>
                </RankingBody>
              ))}
            </Ranking>
          </RankingSection>
          {/* <RankingSection>
            <Ranking>
              <RankingHead>年長</RankingHead>
              <RankingBody></RankingBody>
            </Ranking>
            <Ranking>
              <RankingHead>年少</RankingHead>
              <RankingBody></RankingBody>
            </Ranking>
            <Ranking>
              <RankingHead>勤続</RankingHead>
              <RankingBody></RankingBody>
            </Ranking>
          </RankingSection> */}
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allRankingMatchCountJson {
      nodes {
        name
        rank
        result
      }
    }
    allRankingWinAvgJson {
      nodes {
        name
        rank
        result
      }
    }
    allRankingWinContinueJson {
      nodes {
        name
        rank
        result
      }
    }
    allRankingWinCountJson {
      nodes {
        name
        rank
        result
      }
    }
  }
`;

export default RankingPage;
